import { Injectable } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {Statistique} from '../Model/UserSession';

import {Observable, of, Subscription} from 'rxjs';
import {User} from '../Model/User';
import {Supplement} from '../Model/burger';
import firebase from 'firebase';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  x: Statistique [];
  sub: Subscription;
  okk: any;
  user: User;

  constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth , public route: Router ) {
    afAuth.authState.subscribe(user => {


      this.afs.collection('users').doc(firebase.auth().currentUser.uid).snapshotChanges().subscribe(value => this.user = value.payload.data());
      console.log(this.user);
    });
    // this.afAuth.authState.pipe(
    //   switchMap(user => {
    //     if (user) {
    //       return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
    //     } else {
    //       return of(null);
    //     }
    //   })
    // );


    // // tslint:disable-next-line:typedef
    // async logoutUser() {
    //   await this.afAuth.signOut()
    //     // tslint:disable-next-line:only-arrow-functions typedef
    //     .catch(function(error) { alert(error); });
    //   await this.navigation.navigate(['login']);
    //
    // }

    // tslint:disable-next-line:typedef
  }


  // tslint:disable-next-line:typedef
  fermeture(){


    if ( confirm( 'tu veux fermer la caisse ?') ) {
      const newUID = this.afs.createId();

      this.updateTasks( `${newUID}`,

        {
          displayName: this.user.displayName,
          cash : this.user.cash ,
          cb : this.user.cb,
          tva :  this.user.tva,
          tva5  :  this.user.tva5,
          tva10 :  this.user.tva10,
          tva20 :  this.user.tva20,
          date_ouverture: this.user.date_ouverture,
          date_fermeture: new Date(),
          uid: `${newUID}`} );

      this.afs.collection('users').doc(firebase.auth().currentUser.uid).update(
        {statut: false,
          numberCommande: 1,
          displayName: '',
          // cash : 0 ,
          // cb : 0,
          // tva :  0,
          // tva5  :  0,
          // tva10 :  0,
          // tva20 :  0,
          // date_ouverture: null,
          // date_fermeture: null,
        }

      );
    } else {
     // @ts-ignore
      this.route.navigate('caisseMaster');
    }






    this.route.navigate(['login']);

  }


  // tslint:disable-next-line:typedef
  updateTasks(boardId: string, historique: User) {



    return this.afs
      .collection(`historique/${firebase.auth().currentUser.uid}` + '/historique')
      .doc(boardId)
      .set(historique);

  }

  // tslint:disable-next-line:typedef
  //
  // tslint:disable-next-line:typedef
  // getUserBoards() {
  //   return this.afAuth.authState.pipe(
  //     switchMap(user => {
  //
  //       if (user) {
  //         return this.afs.collection<Statistique>('users', ref =>
  //           ref.where('uid', '==', user.uid)
  //         )
  //           .valueChanges();
  //       }
  //     }),
  //     // map(boards => boards.sort((a, b) => a.priority - b.priority))
  //   );
  //
  // }

}
