import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';

import {DataService} from './data.service';
import {LoginService} from './login.service';
import {PanierService} from './panier.service';

import {StatistiqueComponent} from '../statistique/statistique.component';



// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  // tslint:disable-next-line:variable-name
  constructor(public numPanier: PanierService, public dialog: MatDialog, public dialogX: MatDialog , private _bottomSheet: MatBottomSheet , public route: Router, public xt: LoginService , public yx: DataService) {
  }



  // tslint:disable-next-line:typedef
  openStatistique(link?: any) {
    const dialogRef = this.dialog.open(StatistiqueComponent ,  {
      height: '80vh',
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '90vh',
      // maxWidth: '50vw',
      data: {
        // uid: link ?  link.uid : '',
        // ingredient: link ?   link.ingredient  : '',
        // name: link ?   link.name  : '',
        // prix: link ?   link.prix  : '',
        // photo: link ? link.url : ''
      }
    });

  }



}
