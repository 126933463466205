


<!--<div (click)="loginUser('salut@salut.com','salut@salut.com')"> login</div>-->



<div   *ngIf="isAuth === false" style=" position: center; background: linear-gradient(-45deg, #008cff, #0048ff); height: 100vh ;  flex:1 ; width: 100vw;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;">
   <div style="margin: 2vh">

     <div  style="color:#FFFFFF;  text-align: center; font-size: 35px; font-family: batmfa" >
       <img style="height: 35vh;padding: 2vh" src="./assets/CAISSE33__1_-removebg-preview.png"><br><br>

       Back office <br></div>

   </div>
  <div>
    <input [(ngModel)]="email" placeholder="email"    type="text"     name="1" id="1"><br>

    <input [(ngModel)]="pass"  placeholder="password" type="password" name="1" id="2">
  </div>
  <div style="margin: 2vh">
    <button    class="btn btn-dark" style="border-radius: 50px ;text-align: center; font-size:30px" (click)="loginUser(this.email , this.pass)"> CONNECTION</button>
  </div>

<div style="text-align: center; color:white"><br>
  <a style="color: #ffffff" href="tel:0669669010">06.69.66.90.10</a><br>
  <a style="color: #ffffff" href="mailto:contact@caisse33.fr">contact@caisse33</a>
</div>

</div>


<!--  <div  class="card" style="margin:15px ; display: flex" >-->
<!--    <img style="height:150px ; width: 250px" src="./assets/open.png"/>-->
<!--  </div>-->

    <div *ngIf="isAuth === true" style="  display: flex;

  height: 100vh;
  flex-direction: column;
   background: linear-gradient(-45deg, #0048ff, #7dbdf1);
  text-align: center;
  align-items: center;
  width: 100vw;

">
      <mat-toolbar style=" background: linear-gradient(-45deg, #ee7752, #e73c7e)">


        <span >Bonjour {{this.log.user?.responsable}}</span>
        <span class="example-spacer"></span>

        <button  class="btn btn-danger" (click)="signOutUser() ">
          SE DECONNECTER
        </button>

        <!--  <div   *ngIf="isAuth  ===  null "-->

        <!--         style="flex-wrap: wrap; display: flex ;align-items: center ; height: 30vh; justify-items: center" >-->
        <!--    <img style="height: 30vh" src="assets/loading.gif" >-->
        <!--  </div>-->

      </mat-toolbar>
      <div  *ngIf="this.log.user?.statut === true" style=" flex:1 ;

       font-family: 'Arial Black', serif;
       font-size: 2vh;
justify-content: space-around;
        background: #44f83e ;
         width: 100vw ;
        display: flex;
        flex-direction: column;
       align-items: center;">
    {{this.log.user?.date_ouverture.toDate().toLocaleString()}}<br><br>
<!--        Caissier:{{this.log.user?.displayName}}<br><br>-->

<!--Commande:{{this.log.user?.numberCommande}}<br>-->
<div>
  CASH <h3 style="font-size: 10vh ;margin: 10px;">{{this.log.user?.cash | number}}</h3><br>
  CB<h3 style="font-size: 10vh;margin: 10px;">{{this.log.user?.cb | number}}</h3><br>
</div>



      </div>
      <div  *ngIf="this.log.user?.statut === false" style=" flex:1 ;
       text-align: center;
       font-family: 'Arial Black', serif;


         width: 100vw ;
        display: flex;
   justify-content: center;">
        dernière Overture {{this.log.user?.date_ouverture.toDate().toLocaleString()}}<br>
        dernière cloture {{this.log.user?.date_fermeture.toDate().toLocaleString()}}<br>
        dernier Caissier:{{this.log.user?.displayName}}<br><br>

         nombre de   Commande:{{this.log.user?.numberCommande}}<br>
        <div >
         <div>
           cash:{{this.log.user?.cash | number}}
         </div>
         <div>
           cb:{{this.log.user?.cb | number}}
         </div>
        </div>
      </div>

      <div style=" flex:1 ; width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;">
        <button style="height: 150px; width: 150px;  background: linear-gradient(-45deg, #b9c41b, #e73c7e); border-radius: 50%" class="btn btn-warning" (click)="this.maodalService.openStatistique()">STATISTIQUE</button>
      </div>

      <div style=" flex:1  ; width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;">
        <button style="height: 150px; width: 150px; background: linear-gradient(-45deg, #2f00a5, #e73c7e); border-radius: 50%" class="btn btn-warning" > OPTION</button>
      </div>



</div>



<!--            <div  *ngIf="this.log.user?.statut ===  true " >-->

<!--            <button  style="flex-wrap: wrap; display: flex ;align-items: center;height: 30vh; justify-items: center "  class=" btn btn-warning"-->
<!--                     (click)="this.maodalService.openSession2()">ATTENTION CAISSE OUVERTE-->
<!--            </button>-->

<!--           </div>-->





<!--<div  class="card" style="margin:15px"  (click)="this.maodalService.openStatistique()">-->
<!--  <img style="height:150px ; width: 150px" src="./assets/statistic.png"/>-->
<!--</div>-->
<!--<div  class="card" style="margin:15px"  >-->
<!--  <img style="height:150px ; width: 150px" src="./assets/statistic.png"/>-->
<!--</div>-->
