import {Injectable} from '@angular/core';
import {Supplement} from '../Model/burger';
import {FormArray} from '@angular/forms';
import {ElectronService} from 'ngx-electron';
import {Observable, Subject, Subscription} from 'rxjs';
import { interval } from 'rxjs';
import {take} from 'rxjs/operators';
import {LoginService} from './login.service';
import {DataService} from './data.service';
// @ts-ignore
import Keyboard from 'simple-keyboard';
import {MatDialogRef} from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class PanierService {
  Panierlist = [];
  keyboard1: Keyboard;
    validation: any;

  wech: Observable<string>;
   total: 0;
   tva: 0;
   tva5: 0;
   tva10: 0;
   tva20: 0;
  private printerDispo: boolean;
  public value: any;
  public detail: any;
   checkPrinter: boolean;
  constructor(private electronService: ElectronService , public Id: LoginService, public serviceData: DataService) {
  // @ts-ignore
 // return  this.Id.id.forEach(value => { this.id = value.idCommandeDirect; });
// @ts-ignore

  }


  // tslint:disable-next-line:typedef
  totalPrice() {

    this.total = 0;



    this.Panierlist.forEach(x => x.TotalSupplement = x.supplement.reduce((val, cur) => val + cur.prix, 0));
    this.Panierlist.forEach(a => this.total += a.TotalSupplement);
    this.Panierlist.forEach(a => this.total += a.prix);
    this.Panierlist.forEach(a => this.total += a.boissonPrix);
    this.tva = this.Panierlist.reduce((val, cur) => val + cur.tva, 0);
    console.log(this.tva);

    this.tva5 = this.Panierlist.filter(d => d.typeTva === '5.5').reduce((val, cur) => val + cur.tva, 0);
    console.log('total 5% '  + this.tva5);
    this.tva10 = this.Panierlist.filter(d => d.typeTva === '10').reduce((val, cur) => val + cur.tva, 0);
    console.log('Total 10%: ' + this.tva10);
    this.tva20 = this.Panierlist.filter(d => d.typeTva === '20').reduce((val, cur) => val + cur.tva, 0);
    console.log('Total 20% ' + this.tva20);


    // this.Panierlist.forEach(x => x.TotalTVA = x.tva.reduce((val, cur) => val + cur.tva, 0));


    // this.tva5 = 0;
    // this.Panierlist.forEach(x => x.tva5 = x.supplement.reduce((val, cur) => val + cur.prix, 0));

  }

  // tslint:disable-next-line:typedef
  delete(i) {
    this.Panierlist.splice(i, 1);
    this.totalPrice();
  }

  // tslint:disable-next-line:typedef
  deleteSupplement(x: number, i: number) {
    this.Panierlist[x].supplement.splice(i, 1);
    this.totalPrice();
  }

  // tslint:disable-next-line:typedef
  deleteDosette(x: number, i: number) {
    this.Panierlist[x].sauce.splice(i, 1);
    this.totalPrice();
  }

  // tslint:disable-next-line:typedef
  deleteSauceSand(x: number, i: number) {
    this.Panierlist[x].sauceSand.splice(i, 1);
    this.totalPrice();
  }

  // tslint:disable-next-line:typedef
  deleteSauceLegume(x: number, i: number) {
    this.Panierlist[x].legume.splice(i, 1);
    this.totalPrice();
  }

  // tslint:disable-next-line:typedef
  deleteSuppression(i: number, x: any) {
    this.Panierlist[x].supprimer.splice(i, 1);
    this.totalPrice();
  }
  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  // X(){
  //
  //   this.electronService.ipcRenderer.send('asynchronous-message', this.total * 100);
  //   this.electronService.ipcRenderer.on(   'asynchronous-reply',  (event, arg) => {
  //
  //     console.log(arg);
  //     if (arg === -3 ){
  //       this.validation = 'TPE INDISPONIBLE ';
  //     }
  //     if (arg === 9769 ){
  //       this.validation = 'Opération annulée';
  //       this.electronService.ipcRenderer.removeAllListeners('asynchronous-reply');
  //     }
  //     if (arg === 0 ){
  //       this.validation = 'Paiement Validé';
  //       this.detail = { numberCommande: this.id, devise: 'cb sans contact' , total: this.total ,  donne: this.total , rendu: 0 , date: new Date()};
  //
  //
  //       this.impression();
  //
  //
  //       this.electronService.ipcRenderer.removeAllListeners('asynchronous-reply');
  //       // this.impression();
  //     }
  //     if (arg === -16 ){
  //       this.validation = 'TPE est déja en cours de paiement';
  //     }
  //     if (arg === -1 ){
  //       this.validation = 'TPE INDISPONIBLE';
  //
  //
  //
  //       this.electronService.ipcRenderer.removeAllListeners('asynchronous-reply');
  //
  //     }
  //     if (arg === -25 ){
  //       this.validation = 'TPE Echec';
  //
  //     }
  //     if (arg === 16640 ){
  //       this.validation = 'ABSENCE DE CARTE';
  //       this.electronService.ipcRenderer.removeAllListeners('asynchronous-reply');
  //     }
  //
  //     if (arg === 6161 ){
  //       this.validation = 'CARTE PERIMEE';
  //       this.electronService.ipcRenderer.removeAllListeners('asynchronous-reply');
  //
  //       // this.detail = {  devise: 'cash' };
  //       // this.impression();
  //     }
  //   });
  //
  // }


  // tslint:disable-next-line:typedef
  createObservableService() {
    this.validation = '';

    return  this.wech =  new Observable(
      observer => {
        setInterval(() =>
            observer.next(this.validation)
          , 1000);
      }
    );

  }
  // tslint:disable-next-line:typedef
  PaiementCb(){

    this.detail = {tva5: this.tva5, tva10: this.tva10, tva20: this.tva20, tva: this.tva, numberCommande: this.Id.user.numberCommande, devise: 'cb' , total: this.total ,  donne: this.total , rendu: 0 , date: new Date()};

    this.impression();

  }
  // tslint:disable-next-line:typedef
  impression(){

    const arr2 = this.Panierlist.filter(d => d.print === true );
    // const detail = this.detail;
    // const tableau  = { id: this.Id.user.numberCommande};
    this.serviceData.Tableau( this.Panierlist, this.detail);
    console.log(this.detail);
    const x = this.serviceData.config.ipImprimanteCaisse;
    this.electronService.ipcRenderer.send('ticketClient', {test: this.detail, test2: this.Panierlist, test3: this.Id.user , config: this.serviceData.config});

    arr2.splice(0, 0, this.detail);
    this.total = null;
    this.Panierlist = [];
}
  // tslint:disable-next-line:typedef
  printx(i, Conso: string) {

    const x = this.serviceData.config.ipImprimanteCuisine;
    this.Panierlist[i] = { ... this.Panierlist[i], id: this.Id.user.numberCommande , x, conso: Conso , date: new Date()};
    this.Panierlist[i].print = false;
    this.electronService.ipcRenderer.send('ticketCuisineIndividuel',    this.Panierlist.slice(i , i + 1) )  ;
  }
  // tslint:disable-next-line:typedef
   printNull(i, Conso: string) {

    const x = this.serviceData.config.ipImprimanteCuisine;
    this.Panierlist[i] = { ... this.Panierlist[i], id: this.Id.user.numberCommande , x, conso: Conso , date: new Date()};
    this.Panierlist[i].print = false;
  }
// tslint:disable-next-line:typedef
reset(){

  this.total = null;
  this.Panierlist = [];
}

}
