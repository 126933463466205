import { Injectable } from '@angular/core';

import {AngularFirestore, DocumentData} from '@angular/fire/firestore';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {Employer, User} from '../Model/User';

import {Burger, Ingredient, Boisson, Sauce, Supplement, Legumes, Tacos, Config} from '../Model/burger';
import {Costome} from '../Model/costomStructure';
import firebase from 'firebase';
import {snapshotChanges} from '@angular/fire/database';
import {reduce, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import FieldValue = firebase.firestore.FieldValue;
import {LoginService} from './login.service';
import Timestamp = firebase.firestore.Timestamp;
import {AbstractControl} from '@angular/forms';



@Injectable({
  providedIn: 'root'
})
export class DataService {

  histo: User[] ;
  hor: User;
  value: User[];
  sauce: Sauce[];
  boisson50cl: Boisson[];
  boisson33cl: Boisson[];
  ok: Observable<User>;
  XXX: Costome[];
  costom: Costome[];
  burgerMaison: Burger[];
  viande: Supplement[];
  viandeTacos: Tacos[];
  fromage: Supplement[];
  legumes: Legumes[];
  burger: Burger[];
  id: User[];
  teste: User[];
  clock = new Date();
  valueCb: User[];
  y: User[];
  x = null;
  limite = 10;
  config: Config;
  logs: Array<string> = [];
  employer: Employer[];
  constructor(private afs: AngularFirestore , public route: Router, public logService: LoginService) {



  }
  // tslint:disable-next-line:typedef
  getInitialisation(){
    this.getViandeTacos().subscribe(data => {
      this.viandeTacos = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Tacos;
      });
    });
    this.getBurgerMaison().subscribe(data => {
      this.burgerMaison = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Burger;
      });
    });
    this.getLegumes().subscribe(data => {
      this.legumes = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Legumes;
      });
    });
    this.getSauce().subscribe(data => {
      this.sauce = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Sauce;
      });
    });
    this.getBoisson33cl().subscribe(data => {
      this.boisson33cl = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Boisson;
      });
    });
    this.getBoisson50cl().subscribe(data => {
      this.boisson50cl = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Boisson;
      });
    });
    this.getViande().subscribe(data => {
      this.viande = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Supplement;
      });
    });
    this.getViandeTacos().subscribe(data => {
      this.viandeTacos = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Tacos;
      });
    });
    this.getCostom().subscribe(data => {
      this.costom = data.map(e => {
        // console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Costome;
      });
    });
    this.getXXX().subscribe(data => {
      this.XXX = data.map(e => {
        console.log(e.payload.doc.data());
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Costome;
      });
    });
    console.log(this.employer);
    this.afs.collection('config').doc(firebase.auth().currentUser.uid).snapshotChanges().subscribe(value => this.config = value.payload.data());


  }



  reload(newUID: any){
   this.getValue(newUID).subscribe(data2 => {

     // tslint:disable-next-line:no-shadowed-variable
     this.value = data2.map(e => {

       // console.log(e.payload.doc.data());
       return {
         id: e.payload.doc.id,
         ...e.payload.doc.data()
       } as User;
     });
   });
 }


 FullEmployer(){
   this.getEmployer().subscribe(data => {
     this.employer = data.map(e => {
       // console.log(e.payload.doc.data());
       return {
         id: e.payload.doc.id,
         ...e.payload.doc.data()
       } as Employer;
     });
   });
 }

getEmployer(){
  return this.afs.collection('employer').doc(firebase.auth().currentUser.uid).collection('employer').snapshotChanges();
}
  getXXX() {

    // tslint:disable-next-line:max-line-length
    return this.afs.collection('navbar').doc(firebase.auth().currentUser.uid).collection('navbarSupplement' , ref => ref.orderBy('num', 'asc')).snapshotChanges();
  }
  getCostom() {

    // tslint:disable-next-line:max-line-length
    return this.afs.collection('navbar').doc(firebase.auth().currentUser.uid).collection('navbarMaster' , ref => ref.orderBy('num', 'asc')).snapshotChanges();
  }
  getLegumes() {

    return this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection('legume', ref => ref.orderBy('num', 'asc')).snapshotChanges();
  }
  getSauce() {

    return this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection('sauce', ref => ref.orderBy('num', 'asc')).snapshotChanges();
  }
  getViande() {
    return this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection('viande', ref => ref.orderBy('num', 'asc')).snapshotChanges();

  }
  getViandeTacos() {
    return this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection('viandeTacos', ref => ref.orderBy('num', 'asc')).snapshotChanges();

  }

  // tslint:disable-next-line:typedef
  getBurgerMaison() {

    return this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection('BurgerMaison', ref => ref.orderBy('prix', 'asc')).snapshotChanges();
  }

  // tslint:disable-next-line:typedef
  getBoisson50cl() {
    return this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection('boisson50cl', ref => ref.orderBy('num', 'asc')).snapshotChanges();

  }

  // tslint:disable-next-line:typedef
  getBoisson33cl() {

    return this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection('boisson33cl', ref => ref.orderBy('num', 'asc')).snapshotChanges();
  }

  // tslint:disable-next-line:typedef
  getHistorique(news , news2 , news3) {
    //

    return this.afs.collection('historique').doc(`${firebase.auth().currentUser.uid}`).collection('historique', ref => {
      let query: firebase.firestore.Query = ref;

      if (news.value) {
        query = query.where('date_ouverture', '>', news.value);
      }
      if (news2.value) {
        query = query.where('date_ouverture', '<', news2.value);
      }
      if (news3) {
        query = query.where('displayName', '==', news3);
      }
      console.log(ref);
      return query;

    }).snapshotChanges() ;


    // return this.afs.collection('historique').doc(`${firebase.auth().currentUser.uid}`) .collection('historique').snapshotChanges();

  }
  // tslint:disable-next-line:typedef
  getValue(newUID: any) {


     return this.afs.collection('users').doc(`${firebase.auth().currentUser.uid}`) .collection(newUID, ref => ref.orderBy('date', 'desc').limit(8)).snapshotChanges();

    }

  // tslint:disable-next-line:typedef
  Tableau(tableau, detail: any) {

    const newUID = this.afs.createId();
    console.log(tableau);
    this.afs.collection('users').doc(`${firebase.auth().currentUser.uid}`).collection(this.logService.user.uid).doc(`${newUID}`).set(
      {
        uid: newUID,
        date: detail.date,
        devise: detail.devise,
        donne: detail.donne,
        rendu: detail.rendu,
        total: detail.total,
        numberCommande: detail.numberCommande,
        commande: tableau,
        tva5: detail.tva5,
        tva10: detail.tva10,
        tva20: detail.tva20,
        tva: detail.tva




      },
      {merge: true})
    ;
    if (detail.devise.indexOf('cash') !== -1) {
      this.afs.collection('users').doc(`${firebase.auth().currentUser.uid}`).update(
        {
          tva: FieldValue.increment(detail.tva),
          tva5: FieldValue.increment(detail.tva5),
          tva10: FieldValue.increment(detail.tva10),
          tva20: FieldValue.increment(detail.tva20),
          cash: FieldValue.increment(detail.total),
          numberCommande: FieldValue.increment(+1)
        },
        // tslint:disable-next-line:align
      );
    } else {
      this.afs.collection('users').doc(`${firebase.auth().currentUser.uid}`).update(
        {
          cb: FieldValue.increment(detail.total),
          numberCommande: FieldValue.increment(+1),
          tva: FieldValue.increment(detail.tva),
          tva5: FieldValue.increment(detail.tva5),
          tva10: FieldValue.increment(detail.tva10),
          tva20: FieldValue.increment(detail.tva20),
        },
      );
    }
  }
// tslint:disable-next-line:typedef
  NewsElementSupplement(tableau) {
    const newUID = this.afs.createId();

    this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection(tableau.path).doc(`${newUID}`).set(
      {
        uid: newUID,
        print: tableau.print,
        name: tableau.name,
        prix: tableau.prix,
        prixMenu: tableau.prixMenu,
        urlMenu: tableau.urlMenu,
        url: tableau.url,
        path: tableau.path,
        filter: tableau.filter,
        legume: tableau.legume,
        sauce: tableau.sauce,
        viande: tableau.viande,
        boisson: tableau.boisson,
        sauceFrite: tableau.sauceFrite,
        viandeMax: tableau.viandeMax,
        prixViande1: tableau.prixViande1,
        prixViande2: tableau.prixViande2,
        prixViande3: tableau.prixViande3,
        prixViande4: tableau.prixViande4,
        solo: tableau.solo,
        ingredient: tableau.ingredient,
        width: tableau.width,
        maxiFormat: tableau.maxiFormat


      },
      {merge: true})
    ;
    // this.afs.collection('user').doc('ZB7jJJY1LZgYBvltbTSu2mZzW3y2').collection(tableau.path).doc(`${newUID}`).collection('ingredient').doc(`${newUID}`).set(
    //  tableau.ingredient[],
    //
    //   {merge: true})
    // ;

  }
  // tslint:disable-next-line:typedef
  NewsElement(tableau) {
    const newUID = this.afs.createId();

    this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection(tableau.path).doc(`${newUID}`).set(
      {
        uid: newUID,
        print: tableau.print,
        name: tableau.name,
        prix: tableau.prix,
        prixMenu: tableau.prixMenu,
        urlMenu: tableau.urlMenu,
        url: tableau.url,
        path: tableau.path,
        filter: tableau.filter,
        legume: tableau.legume,
        sauce: tableau.sauce,
        viande: tableau.viande,
        boisson: tableau.boisson,
        sauceFrite: tableau.sauceFrite,
        sauceFriteGratuite: tableau.sauceFriteGratuite,
        viandeMax: tableau.viandeMax,
        prixViande1: tableau.prixViande1,
        prixViande2: tableau.prixViande2,
        prixViande3: tableau.prixViande3,
        prixViande4: tableau.prixViande4,
        solo: tableau.solo,
        ingredient: tableau.ingredient,
        width: tableau.width,
        maxiFormat: tableau.maxiFormat,
        tva: tableau.tva,
        partage: tableau.partage



      },
      {merge: true})
    ;
    // this.afs.collection('user').doc('ZB7jJJY1LZgYBvltbTSu2mZzW3y2').collection(tableau.path).doc(`${newUID}`).collection('ingredient').doc(`${newUID}`).set(
    //  tableau.ingredient[],
    //
    //   {merge: true})
    // ;

  }
  // tslint:disable-next-line:typedef
  NewsElementIngrediant(tableau) {
    const newUID = this.afs.createId();

    this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection(tableau.path).doc(`${newUID}`).set(
      {
        uid: newUID,
        url: tableau.url,
        ingredient: tableau.ingredient,
        prix: tableau.prix,
        num: tableau.num
      },
      {merge: true})
    ;


  }
  // tslint:disable-next-line:typedef
  SupprimerCostumCreation(typeNav, uid) {


    this.afs.collection('navbar').doc(firebase.auth().currentUser.uid).collection(typeNav).doc(uid).delete()
    ;
    console.log(this.id.map(value1 => value1.date_fermeture));
  }
  // tslint:disable-next-line:typedef
  supprimerIngredient(link: Sauce, sauceSand1: string){
    this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection(sauceSand1).doc(link.uid).delete();
  }
  // tslint:disable-next-line:typedef
  SupprimerCreation(burg, x) {
    this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection(burg).doc(x.uid).delete();
    this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection(burg).doc(x.uid).collection('ingredient').doc(x.uid).delete();


    if (x.url) {
        const storageRef = firebase.storage().refFromURL(x.url);
        storageRef.delete().then(
          () => {
            console.log('Photo removed!');
          },
          (error) => {
            console.log('Could not remove photo! : ' + error);
          }
        );
      }

    }

  supprimeLogoImpimante(x){
    if (x) {
      const storageRef = firebase.storage().refFromURL(x);
      storageRef.delete().then(
        () => {
          console.log('Photo removed!');
        },
        (error) => {
          console.log('Could not remove photo! : ' + error);
        }
      );
    }
  }
  parametreImprimante(parametre){


    if ( confirm( 'tu es sûre ?') ) {



      this.afs.collection('config').doc(firebase.auth().currentUser.uid).update(
        {
          ipImprimanteCaisse: parametre.ipImprimanteCaisse,
          ipImprimanteCuisine: parametre.ipImprimanteCuisine,
          tva: parametre.tva,
          siret: parametre.siret,
          adresse: parametre.adresse,
          nom: parametre.nom
        }

      );

    }

    else {
      // @ts-ignore
      this.route.navigate('caisseMaster');
    }






    this.route.navigate(['login']);

  }
  // tslint:disable-next-line:typedef
  NewsCustomElement(tableau , doc ) {

    const newUID = this.afs.createId();

    this.afs.collection('navbar').doc(firebase.auth().currentUser.uid).collection(doc).doc( `${newUID}` ).set (

      { uid: newUID,
        element: tableau.element,
        num: tableau.num,
        master: tableau.master,
        path: tableau.path,
        oneshoot: tableau.oneshoot,
        url: tableau.url,
        photoValue: tableau.photoValue,

  },
      { merge : true } )
    ;



  }


  updateStyle(link: any, obj: any, mar: string, hei: string, wi: string, heightPho: string, widthPh: string) {
    this.afs.collection('objets').doc(firebase.auth().currentUser.uid).collection(link.path).doc(link.uid).update(

        {
          size: obj,
          margin: mar,
          height: hei,
          wid: wi,
          heightPhoto: heightPho,
          widthPhoto: widthPh
        },
        )
    ;


  }
}
