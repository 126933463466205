import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {LoginComponent} from '../login/login.component';

import {StatistiqueComponent} from '../statistique/statistique.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },

  { path: 'statistique', component: StatistiqueComponent},
  { path: '', redirectTo: 'login', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RouterRoutingModule { }
