import {Component, NgZone, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {LoginService} from '../service/login.service';
import {ModalService} from '../service/modal.service';
import {User} from '../Model/User';
import firebase from 'firebase';
import {DataService} from '../service/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  signupForm: FormGroup;
  errorMessage: string;
//
// createItem = '';
// showLoginUserInputForm = false;
// showCreateUserInputForm = false;
// email = '';
// password = '';
// create doc of type Item that represents the individual GroceryItems nested collection
// groceryItemsDoc: AngularFirestoreDocument<any>;
// groceryItems: Observable<any[]>;
//  user: User;

// tslint:disable-next-line:max-line-length
  email: string;
  pass: string;
// tslint:disable-next-line:max-line-length
constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth , public route: Router , public maodalService: ModalService , public log: LoginService
,           private formBuilder: FormBuilder,
            private router: Router) {
  // afAuth.authState.subscribe(user => {
  //
  //
  //   this.afs.collection('users').doc(firebase.auth().currentUser.uid).snapshotChanges().subscribe(value => this.user = value.payload.data());



  // this.getId().subscribe(e => {
  //   this.user = e.payload.data();
  //   console.log(this.user);
  //   // @ts-ignore
  //   // @ts-ignore
  //   return {
  //     id: e.payload.id,
  //   } as User;
  // });

// console.log(loginService.id.statut)

  // this.afAuth.onAuthStateChanged(user => {
  //   if (user) {
  //     // show email in welcome message
  //     this.email = user.email;
  //     // call method that selects all items when user is authenticated
  //     // this.accueil(user.uid);
  //
  //   }
  // });


}

  isAuth: boolean;



  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.initForm();

    firebase.auth().onAuthStateChanged(
      (user) => {
        this.isAuth = !!user;
      }
    );
  }

  // tslint:disable-next-line:typedef
  initForm() {
    this.signupForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]]
    });
  }
  // @ts-ignore
  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  // getId() {
  //   // tslint:disable-next-line:max-line-length
  //   return this.afs.collection('users').doc(`${firebase.auth().currentUser.uid}`).snapshotChanges();
  // }

// async is not necessary here, but using it to control event loop
// async addItem() {
//   const id = this.afs.createId();
//     const groceryItem: any = {
//     value: this.createItem,
//     id
//   };
//
//   await this.groceryItemsDoc.collection<any>('GroceryItems').doc(id).set(groceryItem)
//     .then(() => {
//       // when successful clear input field value here
//       this.createItem = '';
//     })
//     .catch((error) => {
//       alert(error);
//     });
// }

// async is not necessary here, but using it to control event loop
// async deleteItem(groceryItem: any) {
//   await this.groceryItemsDoc.collection<any>('GroceryItems').doc(groceryItem.id).delete()
//     .catch((error) => { alert(error); });
// }
//
// showLoginUserForm() {
//   this.showLoginUserInputForm = true;
// }
//
// showCreateUserForm() {
//   this.showCreateUserInputForm = true;
// }

// createUser(email: string, password: string) {
//   this.afAuth.createUserWithEmailAndPassword(email, password)
//     .then(() => {
//       // on success hide create user input form and store variables in login
//       // and then call the login method
//       this.showCreateUserInputForm = false;
//       this.loginUser(email, password);
//     })
//     .catch((error) => {
//       alert(error);
//     });

// tslint:disable-next-line:typedef
loginUser(email: string, password: string) {
  this.afAuth.signInWithEmailAndPassword(email, password)
    .then(() => {
      window.location.reload();
      // on success populate user variables and then select grocery items for that user
      // this.selectItems(this.afAuth.currentUser.uid);
      // this.route.navigate(['login']);

      // window.location.reload();


    })
    .catch((error) => {
      alert(error);
    });
}
//
// accueil(uid: string) {
//   this.groceryItemsDoc = this.afs.doc<any>('user/' + uid);
//   this.groceryItems = this.groceryItemsDoc.collection<any>('GroceryItems').valueChanges();
//   // // turn on logging if you want to see how the requests are sent
//   // this.groceryItemsDoc.collection<GroceryItem>('GroceryItems').auditTrail().subscribe(console.log);
// }
//
// // async is not necessary here, just controlling the event loop
// async logoutUser() {
//   await this.afAuth.signOut()
//     .catch(function(error) { alert(error); });
//
//   this.email = '';
//   this.password = '';
//   this.showLoginUserInputForm = false;
//   this.showCreateUserInputForm = false;
// }
//
// // tslint:disable-next-line:typedef
// cancelButton() {
//   this.showLoginUserInputForm = false;
//   this.showCreateUserInputForm = false;
// }
  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  signOutUser() {
    firebase.auth().signOut().then(r => {
      window.location.reload();
    });
  }

  // signInUser(email: string, password: string) {
  //   return new Promise(
  //     (resolve, reject) => {
  //       firebase.auth().signInWithEmailAndPassword(email, password).then(
  //         () => {
  //           resolve();
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //     }
  //   );
  // }
}
