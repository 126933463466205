




<mat-form-field appearance="fill" >
  <mat-label> TOUT LE MONDE</mat-label>
  <mat-select [(ngModel)]="value2" >
    <mat-option [value]="option.employer" *ngFor="let option of this.dataService.employer ">{{option.employer }}</mat-option>
  </mat-select>
</mat-form-field>
<div style="display: flex ; flex-wrap: nowrap">


<mat-form-field appearance="fill" >
  <mat-label>Enter la date</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Start date">
    <input matEndDate formControlName="end" placeholder="End date" >
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker  ></mat-date-range-picker>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error   *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>
  <button class="btn btn-success"  *ngIf="range.controls.start.value &&range.controls.end.value && button " [disabled]="range.invalid"  (click)="this.refresh(range.controls.start, range.controls.end , this.value2) ;">VALIDER</button>

  <mat-spinner *ngIf="button=== false" [diameter]="50"></mat-spinner>

</div>



<br><button mat-stroked-button
            [ngStyle]="{
                             'background': this.isAdmin ?  'rgba(73,208,41,0.58)' : '' ,
                        'transition': this.isAdmin ?'all 1s ease-out' : 'all 1s ease-out'}" (click)="toggleAdmin()">taxe</button>

<div style="overflow: auto; height: 300px">
<table id="table" mat-table [dataSource]="dataSource "  class="mat-elevation-z8">
  <!-- Item Column -->



  <ng-container  matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef> Employer </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.displayName}} </td>
    <td mat-footer-cell *matFooterCellDef>Total </td>
  </ng-container>

  <!-- Cost Column -->
  <ng-container matColumnDef="ouverture" >
    <th mat-header-cell *matHeaderCellDef style="color:black"> Ouverture </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.date_ouverture.toDate().toLocaleString()}} </td>-->
    <td mat-footer-cell *matFooterCellDef>{{range.controls.start.value |date:'short'}} </td>
  </ng-container>
  <ng-container matColumnDef="fermeture">
    <th mat-header-cell *matHeaderCellDef> Fermeture </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.date_fermeture.toDate().toLocaleString()}} </td>-->
    <td mat-footer-cell *matFooterCellDef>{{range.controls.end.value |date:'short'}} </td>
  </ng-container>

  <ng-container  matColumnDef="cb" >
    <th mat-header-cell style="color:black" *matHeaderCellDef> Cb </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.cb| number}} </td>-->

    <td mat-footer-cell style="color:green"   *matFooterCellDef> {{this.cb| number}} </td>
  </ng-container>


  <ng-container matColumnDef="cash">
    <th mat-header-cell *matHeaderCellDef> Cash </th>
    <td mat-cell style="color:black" *matCellDef="let transaction"> {{transaction.cash| number}} </td>-->
    <td mat-footer-cell style="color:#800000" *matFooterCellDef> {{this.cash | number}} </td>
  </ng-container>
  <ng-container matColumnDef="total">
    <th mat-header-cell style="color:black" *matHeaderCellDef> Total </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.cash + transaction.cb| number}} </td>-->
    <td mat-footer-cell *matFooterCellDef> {{this.cash + this.cb | number}} </td>
  </ng-container>
  <ng-container style="color:black" matColumnDef="tva5">
    <th mat-header-cell *matHeaderCellDef> TVA 5% </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.tva5| number}} </td>-->
    <td mat-footer-cell *matFooterCellDef> {{this.tva5 | number}} </td>
  </ng-container>
  <ng-container matColumnDef="tva10">
    <th mat-header-cell style="color:black" *matHeaderCellDef> TVA 10% </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.tva10| number}} </td>-->
    <td mat-footer-cell style="color:green"  *matFooterCellDef> {{this.tva10 | number}} </td>
  </ng-container>
  <ng-container matColumnDef="tva20">
    <th mat-header-cell *matHeaderCellDef> TVA 20% </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.tva20| number}} </td>-->
    <td mat-footer-cell *matFooterCellDef> {{this.tva20 | number}} </td>
  </ng-container>
  <ng-container matColumnDef="Ttva">
    <th mat-header-cell style="color:black" *matHeaderCellDef> TOTAL TVA </th>
    <td mat-cell *matCellDef="let transaction"> {{transaction.tva5 + transaction.tva10 + transaction.tva20| number}} </td>-->
    <td mat-footer-cell *matFooterCellDef> {{this.tva5 + this.tva10 + this.tva20| number}} </td>
  </ng-container>
  <tr mat-header-row  *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
<!--  <mat-table #table [dataSource]="dataSource">-->



<!--</div>-->
<div class="container">
  <div class="row">
    <div class="col-md-4" >



</div>
</div>

</div>
  <mat-paginator #paginator
                 [pageSize]="100"
                 [pageSizeOptions]="[3, 5 ,20, 100]">
  </mat-paginator>
</div>
  <div  *ngIf="dataSource.data.length>0 && this.button === true"
        style="display: flex; flex-wrap: wrap; justify-content: space-around; justify-items: center  ; width: 100vw">
    <button (click)="download()" class="btn btn-primary">TELECHARGER PDF</button>

  </div>


