import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../service/data.service';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {User} from '../Model/User';
import {MatTableDataSource} from '@angular/material/table';
import autoTable from 'jspdf-autotable';
import {formatDate} from '@angular/common';
const { jsPDF } = require('jspdf'); // will automatically load the node version



@Component({
  selector: 'app-statistique',
  templateUrl: './statistique.component.html',
  styleUrls: ['./statistique.component.css']

})

export class StatistiqueComponent implements OnInit{
  // @ts-ignore



  constructor(private changeDetectorRef: ChangeDetectorRef , public dataService: DataService) {

  }


  value: User[];
  cash;
  cb;
  tva5;
  tva10;
  tva20;
  button = true;
  isAdmin = true;
  displayedColumns: string[];
  value2;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    displayName: new FormControl(),

  });

  // @ts-ignore
  // dataSource = new MatTableDataSource<User>(this.dataService.histo);

  dataSource = new MatTableDataSource<User>([]);
  cinq: number;
  allColumns: string[] = ['displayName', 'ouverture', 'fermeture' , 'cb', 'cash', 'total', 'tva5', 'tva10' , 'tva20', 'Ttva'];



  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  // tslint:disable-next-line:typedef
  checkIfAdmin() {
    this.displayedColumns = this.isAdmin ? this.allColumns : this.allColumns.filter(column => column !== 'tva5').filter(column => column !== 'tva10').filter(column => column !== 'tva20').filter(column => column !== 'Ttva');

  }

  // tslint:disable-next-line:typedef
  toggleAdmin() {
    this.isAdmin = !this.isAdmin;
    this.checkIfAdmin();
  }
  // @ts-ignore

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.dataService.FullEmployer();
    this.dataSource.paginator = this.paginator;
    this.checkIfAdmin();
  }

pdf(){



  // @ts-ignore
  const doc = new jsPDF();
  autoTable(doc, { html: '#my-table' });


  doc.save('table.pdf');

}

  download() {

    const doc = new jsPDF();

    doc.autoTable({html: '#table'});
    doc.save(`${this.range.controls.start.value.toLocaleDateString()} - ${this.range.controls.end.value.toLocaleDateString()}.pdf`);
  }
  // tslint:disable-next-line:typedef
 async refresh(start: AbstractControl, end: AbstractControl, employer3) {
   this.button = false;
   this.dataService.getHistorique(start, end , employer3).subscribe(( data => {
     const promise1 = new Promise((resolve, reject) => {
       setTimeout(() => {
         resolve(  // @ts-ignore
           this.dataSource.data = data.map(e => {
               // console.log(e.payload.doc.data().cb as User);

               return {
                 id: e.payload.doc.id,
                 ...e.payload.doc.data()
               } as User;
             },

           ));
       }, 1000);
     });

     promise1.then((value2) => {
       this.button = true;
       // @ts-ignore
       this.cash = value2.map(value1 => value1.cash).reduce((acc, value) => acc + value, 0);
       // @ts-ignore
       this.cb =  value2.map(value1 => value1.cb).reduce((acc, value) => acc + value, 0);
       // @ts-ignore
       this.tva5 = value2.map(value1 => value1.tva5).reduce((acc, value) => acc + value, 0);
       // @ts-ignore
       this.tva10 = value2.map(value1 => value1.tva10).reduce((acc, value) => acc + value, 0);
       // @ts-ignore
       this.tva20 = value2.map(value1 => value1.tva20).reduce((acc, value) => acc + value, 0);
       // @ts-ignore



     });



    }));


  }







}

