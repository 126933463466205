// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBX7iOB96b-Yp5vhzvR4lgR92XSC6WyZEg',
    authDomain: 'tenka-caisse.firebaseapp.com',
    projectId: 'tenka-caisse',
    storageBucket: 'tenka-caisse.appspot.com',
    messagingSenderId: '1039948055297',
    appId: '1:1039948055297:web:dbde80ca5d9fc0ad495100',
    measurementId: 'G-K8RCK69EJ7'
  },
  registrationCode: ''
};

